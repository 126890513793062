import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Title, Text } from "../components/contentCard"

import { min } from "../utils/theme"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentCard from "../components/contentCard"

const IndexPage = () => {
  const images = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "people.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      person: file(relativePath: { eq: "person.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meeting: file(relativePath: { eq: "room.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      janne: file(relativePath: { eq: "janne2.jpg" }) {
        childImageSharp {
          fixed(height: 300, width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="In English" lang="en" />
      <Container>
        <CenteredWrapper>
          <TitleHeading>
            Credos Oy was founded in 2002 with a mission to help entrepreneurs
            overcome business challenges from financing to compensation. We
            carry out our assignments with confidence in our solid know-how and
            our extensive network.
          </TitleHeading>
        </CenteredWrapper>
        <ContentCardsContainer>
          <ContentCard
            title="
            Implementing a corporate strategy may require a corporate restructuring
            or ownership changes. Implementing these changes is complicated and
            requires a diverse set of skills and experience."
            imgProps={{
              fluid: images.person.childImageSharp.fluid,
            }}
            paragraphs={[
              `During corporate restructuring, whether it is a 
              sale of a business, part of a business, or a B2B sale,
              you'll want to rely on an experienced consultant.`,
              `Credos has extensive experience in assisting with mergers,
               acquisitions and value assessments`,
            ]}
          />
          <ContentCard
            imgProps={{
              fluid: images.meeting.childImageSharp.fluid,
            }}
            title="Adequate financing is a key prerequisite for a business growth.
             This is emphasized not only when financing growth
              but also with changes in the company."
            paragraphs={[
              `Ensuring sufficient financing in business is essential for business growth.
              In addition, company transformation situations almost
              always involve financial issues to resolve.`,
              `Credos can help in arranging both equity and debt financing`,
            ]}
          />
          <ContentCard
            imgProps={{
              fluid: images.people.childImageSharp.fluid,
            }}
            title="Fair compensation is one of the most important factors in job satisfaction.
            Performance based remuneration gives employees a fair and commensurate level of compensation."
            paragraphs={[
              `Motivated staff is a company's most important asset.
               Fair compensation and rewards for staff are the best tools to ensure good performance.`,
              `Credos has over 15 years of experience in designing,
               implementing and maintaining a variety of reward systems.`,
            ]}
          />
        </ContentCardsContainer>
        <ContactUs>
          <Title>Contact Us</Title>
          <a name="contact-us" />
          <Text>
            If you wish to talk to us about how we may help your business or
            have any enquiries don't hesitate to contact us. <br />
            You can reach us via the following email address and phone number.
          </Text>
          <Contact>
            <p>
              <strong>Phone</strong>: +358 400 403 593
            </p>
            <p>
              <strong>Email</strong>: janne.kalliomaki@credos.fi
            </p>
          </Contact>
        </ContactUs>
        <ExtraWrapper>
          <Img fixed={images.janne.childImageSharp.fixed} />
          <BottomText>
            <Title>About Us</Title>
            <Text>
              Credos has extensive experience in corporate management and board
              work. We assist our client companies in strategic planning and
              implementation of their plans.
              <br />
              We are happy to take responsibility for implementing our plans by
              participating in the board of directors of the company.
            </Text>
            <Text>
              <strong>Janne Kalliomäki</strong>, Chairman of the Board and CEO
              of Credos, has over 30 years of experience working in management
              positions in different companies, including over 10 years as CEO.
              He has also served as chairman or member of the board of directors
              of more than 20 companies.
            </Text>
          </BottomText>
        </ExtraWrapper>
      </Container>
    </Layout>
  )
}

export default IndexPage

const Container = styled.section`
  max-width: 1250px;
  margin: 0 auto;
`
const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem auto;
  padding: 0 1rem;
  max-width: 1000px;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;
  text-align: center;

  ${min("medium")(css`
    padding: 0;
  `)};
`
const Contact = styled.address`
  font-style: normal;
  padding: 0 1rem;
`

const ContentCardsContainer = styled.section`
  margin-bottom: 5rem;
`
const TitleHeading = styled.h2`
  font-weight: normal;
`

const ExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;

  ${min("small")(css`
    flex-direction: row;
  `)};

  ${min("small")(css`
    padding: 0 1rem;
  `)};
`

const BottomText = styled.div`
  flex-basis: 70%;
  padding: 1rem 1rem 2rem;
  ${min("large")(css`
    padding: 1rem;
  `)};
`

const ContactUs = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;

  ${min("medium")(css`
    padding: 1rem;
  `)};
`
